<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <template
      v-for="item in instantWinCollection"
      :key="item.icon"
    >
      <button
        v-if="item.show"
        class="flex items-center nav-button rounded-md w-full relative"
        :class="{ 'active': isLinkCurrentRoute(item.linkTo), 'highlight': item.highlight }"
        size="2xs"
        @click.prevent.stop="handleRedirectTo(item.linkTo, item.directLink)"
      >
        <SvgIcon
          :icon="getIcon(item)"
          height="20"
          width="20"
          class="icon"
        />
        <div v-if="navState === 'full'" class="label">
          <span v-if="item.icon !== 'metawinStudios'">
            {{ item.label }}
          </span>
          <span v-else>
            <span class="text-blue-500">Metawin</span> Studios
          </span>
        </div>
      </button>
    </template>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, gamesMenuItems, } = storeToRefs(uiStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();
const { $sanity, $rollbar, } = useNuxtApp();

const instantWinCollection = computed(() => gamesMenuItems.value.navListItems);

fetchCmsData();

function fetchCmsData() {
  if (gamesMenuItems.value.length) {
    return;
  }
  $sanity.fetch(
    `*[_type == "nav-collections" && collectionId == "instant-win"][0] {
      collectionId,
      navListItems[]->,
      show,
    }`
  ).then((res) => {
    gamesMenuItems.value = res;

    gamesMenuItems.value.navListItems.unshift({
      show: true,
      label: '$1 Million Race',
      icon: 'mwin',
      linkTo: '/million-race',
      class: 'bg-green-500',
      directLink: true,
      highlight: true,
    });
  }).catch((err) => {
    $rollbar.error('NavCollections fetchCmsData::', err);
  });
}

function handleRedirectTo(link, direct) {
  if (direct) {
    sportsBettingRedirect(link);
    return;
  }

  sportsBettingRedirect(link !== 'games' ? `/games/collection/${link}` : '/games');
}

function isLinkCurrentRoute(link) {
  if (link === 'games') {
    return route.path === '/games/';
  } else {
    return route.path.includes(link);
  }
}

/**
 * TODO: Update CMS and remove.
 */
function getIcon(item) {
  if (item.linkTo === 'bonus-buy') {
    return 'bonusBuy';
  }
  if (item.linkTo === 'recent') {
    return 'recent';
  }
  if (item.linkTo === 'popular') {
    return 'popular';
  }
  if (item.linkTo === 'reel-race-exclusives') {
    return 'reelRace';
  }
  if (item.linkTo === 'top-20-rtps') {
    return 'topRtp';
  }
  if (item.linkTo === 'plinkos') {
    return 'plinkonav';
  }
  return item.icon;
}
</script>

<style lang="scss" scoped>
.navigation-section {
  &.collapsed {
    .nav-button.category:hover {
      .label {
        color: white;
      }
    }
  }

  .category {
    .icon, .label {
      color: #FFC100;
    }
  }
  .category.active {
    .icon, .label {
      color: white;
    }
  }
}
</style>
