<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <button
      v-for="item in items"
      :key="item.icon"
      class="flex items-center nav-button rounded-md w-full relative"
      :class="{ 'active': item.isActive, 'hidden' : item?.hidden }"
      size="2xs"
      @click.prevent.stop="item.action()"
    >
      <SvgIcon
        :icon="item.icon"
        height="20"
        width="20"
        class="icon"
      />
      <div v-if="navState === 'full'" class="label">
        {{ item.label }}
      </div>
      <NavigationToolTip :label="item.label"/>
    </button>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();
const { $config, } = useNuxtApp();

const items = computed(() => [
  {
    icon: 'tag',
    label: 'Promotions',
    isActive: route.path.includes('promotions') && !route.path.includes('salon-rouge'),
    hidden: false,
    action: () => sportsBettingRedirect('/promotions'),
  },
  {
    icon: 'joystick',
    label: 'Providers',
    isActive: route.path.includes('providers'),
    hidden: false,
    action: () => sportsBettingRedirect('/providers'),
  },
  {
    icon: 'medal',
    label: 'Winners',
    isActive: route.path.includes('winners'),
    hidden: false,
    action: () => sportsBettingRedirect('/winners'),
  },
  {
    icon: 'activity',
    label: 'Activity',
    hidden: false,
    isActive: route.path.includes('activity'),
    action: () => sportsBettingRedirect('/activity'),
  },
]);
</script>
